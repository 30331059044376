import React, { Component } from 'react';
import { notification, Modal } from 'antd';
import LogRocket from 'logrocket';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import AuthLayout from '@/components/AuthLayout';
import { AuthInfo, ChallengeInfo } from '../../type';
import ChallengePanel from './components/ChallengePanel';
import api from '@/services';
import auth from '../auth';
import {
  getUrlQueryParams,
  setToken,
  setUser,
  setLoginFlag,
  addTokenToUrl,
} from '@/utils/util';
import styles from './index.module.less';

export interface ChallengePageProps extends RouteComponentProps {}
export interface ChallengePageState {
  email: string;
  challengeData: ChallengeInfo;
  ref: string;
  counter: number;
  disableMfaSent: boolean;
  loading: boolean;
}
export interface ChallengePageRouteState {
  challengeData: ChallengeInfo;
  email: string;
}
class ChallengePage extends Component<ChallengePageProps, ChallengePageState> {
  constructor(props: ChallengePageProps) {
    super(props);
    const {
      location: { state },
    } = props;
    const { challengeData, email } = (state || {}) as ChallengePageRouteState;
    const params = getUrlQueryParams();
    this.state = {
      email,
      challengeData: { ...(challengeData || {}) },
      ref: decodeURIComponent((params.ref as string) || ''),
      counter: 180,
      disableMfaSent: false,
      loading: false,
    };
  }
  timeout: number | undefined;
  disableModal: any;
  componentDidMount() {
    // clear state in history. then if refresh page, redirect to login
    window.history.replaceState({}, document.title);
    // manually invalidate page in 180 sec
    this.countDown(180);
  }

  loading = (loading = false) => {
    this.setState({
      loading,
    });
  };
  isValidState = () => {
    const { email, challengeData, counter } = this.state;
    return (
      counter > 0 &&
      email &&
      challengeData.session &&
      challengeData.challengeName === 'SOFTWARE_TOKEN_MFA'
    );
  };
  countDown = (counter: number) => {
    this.setState({ counter }, () => {
      if (counter > 0) {
        this.timeout = window.setTimeout(() => {
          this.countDown(--counter);
        }, 1000);
      }
    });
  };
  onSubmit = async (formData: any) => {
    const { mfaCode } = formData;
    const {
      email,
      challengeData: { challengeName, session, challengeParameters },
    } = this.state;
    const userName = challengeParameters?.USER_ID_FOR_SRP;
    try {
      this.loading(true);
      LogRocket.identify(email, {
        name: email,
        email,
      });
      LogRocket.track('mfa auth start', { email });
      const { data }: { data: AuthInfo } = await api.mfaAuth({
        challengeName,
        session,
        challengeResponses: {
          USERNAME: userName,
          SOFTWARE_TOKEN_MFA_CODE: mfaCode,
        },
      });
      LogRocket.track('mfa auth success', { email });
      const {
        auth: {
          accessToken,
          refreshToken,
          idToken,
          // expiresIn = 36000
        },
        oneTimeToken,
        user,
      } = data;
      const { email: userEmail, identityId } = user;
      const expires = 365;
      setToken({ accessToken, refreshToken, idToken }, { expires });
      setUser({ email: userEmail, identityId }, { expires });
      setLoginFlag();
      this.redirect(oneTimeToken);
    } catch (e: any) {
      LogRocket.track('mfa auth error', { email, message: e.message });
      // set count down to 0 to invalidate page
      if (e.message?.indexOf('session') > -1) {
        this.countDown(0);
      } else {
        notification.error({
          message: 'MFA error',
          description: e.message || 'Please try again or contact the admin',
        });
      }
    } finally {
      this.loading(false);
    }
  };
  redirect = (token: string) => {
    const { ref } = this.state;
    if (ref) {
      window.location.href = addTokenToUrl(ref, token);
    } else {
      notification.warning({
        message: 'No ref found',
        description: 'Login success, but no [ref] param found in url address',
      });
    }
  };
  invalidatePage() {
    const { counter } = this.state;
    if (counter <= 0) {
      notification.error({
        message: 'MFA timeout, please login again',
        key: 'mfaError',
      });
    }
    return (
      <Redirect
        to={{
          ...this.props.location,
          pathname: '/login',
        }}
      />
    );
  }
  // disableMfa = async () => {
  //   try {
  //     const { email } = this.state;
  //     await api.mfaReset({ email });
  //     this.setState({
  //       disableMfaSent: true,
  //     });
  //     clearTimeout(this.timeout);
  //     this.disableModal.destroy();
  //   } catch (e) {
  //     notification.error({
  //       message: 'Turn off MFA error',
  //       description: e.message || 'Please try again or contact the admin',
  //     });
  //   }
  // };
  showDisableModal = () => {
    this.disableModal = Modal.confirm({
      title: 'Turn off MFA',
      // footer: null,
      centered: true,
      width: 570,
      maskClosable: false,
      closable: false,
      content: (
        <div>
          If you have lost access to your Authenticator app and are unable to
          sign in, click the button below to send a verification link to your
          email. The link will allow you to temporarily disable MFA. After
          signing in, you can turn on MFA again to protect your account.
        </div>
      ),
      okText: 'Send verification email',
      onOk: async () => {
        return new Promise((resolve, reject) => {
          const { email } = this.state;

          api
            .mfaReset({ email })
            .then(() => {
              this.setState({
                disableMfaSent: true,
              });
              clearTimeout(this.timeout);
              this.disableModal.destroy();
              resolve(true);
            })
            .catch((e: any) => {
              notification.error({
                message: 'Turn off MFA error',
                description:
                  e.message || 'Please try again or contact the admin',
              });
              reject(e);
            });
        });
      },
    });
  };
  render() {
    const { loading, disableMfaSent } = this.state;
    // if state not valid, redirect to login page
    if (!this.isValidState()) {
      return this.invalidatePage();
    }
    return (
      <div>
        <AuthLayout>
          {disableMfaSent ? (
            <div className={styles.success_msg}>
              Email sent, please check your inbox.
            </div>
          ) : (
            <ChallengePanel
              onSubmit={this.onSubmit}
              onDisable={this.showDisableModal}
              loading={loading}
            />
          )}
        </AuthLayout>
      </div>
    );
  }
}
export default auth(ChallengePage);
