import React, { Component } from 'react';
import { notification } from 'antd';
import { sha512 } from 'js-sha512';
import LogRocket from 'logrocket';

import AuthLayout from '@/components/AuthLayout';
import { LOGIN_SALT } from '@/constants';
import {
  getUrlQueryParams,
  setToken,
  setUser,
  setLoginFlag,
  addTokenToUrl,
} from '@/utils/util';
import api from '@/services';
import { RouteComponentProps } from 'react-router-dom';
import LoginPanel from './components/LoginPanel';
import auth from '../auth';

import styles from './index.module.less';
import ResendModal from '../../components/ResendModal';
// import { getDefaultAppHost } from '../../utils/util';

export interface LoginPageProps extends RouteComponentProps {}
export interface LoginPageState {
  loading: boolean;
  ref: string;
  resendEmail: string;
  resendModalVisible: boolean;
}
class LoginPage extends Component<LoginPageProps, LoginPageState> {
  constructor(props: LoginPageProps) {
    super(props);
    const params = getUrlQueryParams();
    this.state = {
      loading: false,
      ref: decodeURIComponent((params.ref as string) || ''),
      resendEmail: '',
      resendModalVisible: false,
    };
  }

  componentDidMount() {
    // bypass login if accessToken exists
    // if (Cookies.get('accessToken')) {
    //   this.redirect();
    // }
  }

  redirect = (token: string) => {
    const { ref } = this.state;
    if (ref) {
      window.location.href = addTokenToUrl(ref, token);
    } else {
      notification.warning({
        message: 'No ref found',
        description: 'Login success, but no [ref] param found in url address',
      });
      // window.location.href = getDefaultAppHost();
    }
  };
  loading = (loading = false) => {
    this.setState({
      loading: loading,
    });
  };
  onLogin = async (formData: any) => {
    const { email, password, appType } = formData;

    try {
      this.loading(true);
      LogRocket.identify(email, {
        name: email,
        email,
      });
      LogRocket.track('login start', { email });
      const { data } = await api.login(
        {},
        {
          auth: {
            username: email,
            password: sha512(password + LOGIN_SALT),
          },
          headers: {
            Application: appType,
          },
        }
      );
      LogRocket.track('login success', { email });
      const { authResult } = data;
      if (authResult?.challengeName === 'SOFTWARE_TOKEN_MFA') {
        const { history, location } = this.props;
        history.push({
          ...location,
          pathname: '/challenge',
          state: {
            email,
            challengeData: authResult,
          },
        });
      } else {
        const {
          auth: {
            accessToken,
            refreshToken,
            idToken,
            // expiresIn = 36000
          },
          oneTimeToken,
          user,
        } = data;
        const { email: userEmail, identityId } = user;
        const expires = 365;
        // const expires = new Date(Date.now() + expiresIn * 1000);
        // save token to cookies, default alive in 36000 sec
        setToken({ accessToken, refreshToken, idToken }, { expires });
        setUser({ email: userEmail, identityId }, { expires });
        setLoginFlag();
        // window.frames[0].postMessage(JSON.stringify(user), ref);
        this.redirect(oneTimeToken);
      }
    } catch (e: any) {
      LogRocket.track('login error', { email, message: e.message });
      // unconfirmed user
      if (e.code === 4010000002) {
        this.showResendModal(email);
      } else {
        notification.error({
          message: 'Incorrect email or password',
          description: e.message || 'Please try again or contact the admin',
        });
      }
    } finally {
      this.loading(false);
    }
  };
  showResendModal = (resendEmail: string) => {
    this.setState({
      resendEmail,
      resendModalVisible: true,
    });
  };
  hideResendModal = () => {
    this.setState({
      resendModalVisible: false,
    });
  };
  render() {
    const { loading, resendEmail, resendModalVisible } = this.state;
    return (
      <div className={styles.login_page}>
        <AuthLayout>
          <LoginPanel onLogin={this.onLogin} loading={loading} />
        </AuthLayout>
        {/*<iframe*/}
        {/*  src={ref}*/}
        {/*  ref={me => (this.iframe = me)}*/}
        {/*  className={styles.hidden}*/}
        {/*/>*/}
        <ResendModal
          content={
            <div>
              Your account has not been activated yet. To activate, please
              verify your email address by clicking the button and following the
              instructions in the verification email.
            </div>
          }
          email={resendEmail}
          visible={resendModalVisible}
          onCancel={this.hideResendModal}
        />
      </div>
    );
  }
}
export default auth(LoginPage);
